import { INITIAL_STATE } from "store/initialStates/initial-state";
import userReducer from "store/reducers/userReducer";
import createStore from "utils/createStore";
const [
    StoreProvider,
    useStore,
    useDispatch
] = createStore(INITIAL_STATE, userReducer);

export {
    StoreProvider,
    useStore,
    useDispatch
};

