import { createGlobalStyle } from "styled-components";
import { vscroll, imgcover } from '../common-styled/mixin';
import DownArrow from 'assets/icons/chevron-down.svg';

const GlobalStyle = createGlobalStyle`
  * {
      font-family: ${(props) => props?.theme?.fonts?.primary};
      box-sizing: border-box;
  }
  body {
    padding: 0px;
    margin: 0px;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
  }
  a, a:hover{
    text-decoration: none !important;
  }
  h1,h2,h3,h4,h5,h6,ul,li,a,p{
    margin: 0px ;
    padding: 0px;
  }
  li{
    list-style: none;
  }
  *{
    outline: none;
  }
  svg{
    width: 100%;
    height: 100%;
    display: block;
  }
  .app-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    .app-content{
      flex: 1;
      padding: 25px;
      overflow: hidden;
      @media (max-width:1440px){
        padding: 15px
      }
    }
    .white-box{
      background-color: ${(props) => props?.theme?.colors?.white};
      border-radius: 6px;
      padding: 0px 20px 20px;
      flex: 1;
      ${vscroll};
      overflow-y: auto;
      @media (max-width:1440px) {
        padding: 0px 15px 20px;
      }
    }
  }
  .page-header{
    margin-bottom: 20px;
    .page-header-left{
      > *{
        &:not(:last-child){
          margin-right: 15px;
        }
      }
    }
    .search-box{
      width: 360px;
    }
  }
  table{
    width: 100%
  }
  .p-0{
    padding: 0 !important;
  }
  .percentology-table{
    width: 100%;
    thead{
      th{
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        text-transform: capitalize;
        color: #22252F;
        padding: 20px 10px 12px;
        box-shadow: inset 0px -1px 0px ${(props) => props?.theme?.colors?.borderColor};
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 1;
        background-color: ${(props) => props?.theme?.colors?.white};
        .default{
          path{
            opacity: 1;
          }
        }
        .up{
          path{
            &:nth-of-type(2){
              opacity: 0;
            }
          }
        }
        .down{
          path{
            &:nth-of-type(1){
              opacity: 0;
            }
          }
        }
      }
    }
    .info-icon{
      margin-left: 8px;
    }
    tbody{
      td{
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.015em;
        color: ${(props) => props?.theme?.colors?.darkGray};
        padding: 9px 10px;
        border-bottom: 1px solid ${(props) => props?.theme?.colors?.borderColor};
        .user-name-group{
          max-width: 270px;
          overflow: hidden;
          @media (max-width:1440px){
            max-width: 200px;
          }
          .user-img{
            height: 35px;
            width: 35px;
            flex: 0 0 35px;
            max-width: 35px;
            overflow: hidden;
            border-radius: 25px;
            margin-right: 14px;
            display: flex;
            align-items: center;
            img{
              ${imgcover};
              max-width: 100%;
            }
            span{
              background: #dee4e9;
              font-weight: bold;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: -0.015em;
              color: ${(props) => props?.theme?.colors?.darkGray};
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .user-name-block{
            overflow: hidden;
            span{
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100%;
              &:not(:last-child){
                margin-bottom: 1px;
              }
            }
            .user-name{
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              letter-spacing: -0.015em;
              color: ${(props) => props?.theme?.colors?.darkGray};
            }
            .user-email{
              font-size: 12px;
              line-height: 16px;
              letter-spacing: -0.015em;
              color: #727272;
            }
          }
        }
        .action-btn{
          padding: 0px;
          border: none;
          transition: all 0.25s;
          opacity: 0;
          flex: 0 0 24px;
          max-width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          visibility: hidden;
          border-radius: 3px;
          &:not(:last-child){
            margin-right: 11px;
            @media (max-width:1440px){
              margin-right: 5px;
            }
          }
          &:hover{
            background: rgba(111, 123, 132, 0.2);
            i{
              color: ${(props) => props?.theme?.colors.blue};
            }
          }
          &.delete-btn{
            &:hover{
              background-color: rgba(232,113,96,0.3 );
              i{
                color: #D35662;
              }
            } 
          }
          i{
            height: 20px;
            max-width: 20px;
            flex: 0 0 20px;
            display: block;
            color: #6F7B84;
            transition: all 0.25s ease-in-out;
          }
        }
      }
      tr{
        transition: all 0.25s ease-in-out;
        &:hover{
          background: #F7F8F9;
          td{
            .action-btn{
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
  .info-icon{
    color: #AEB8C0;
    height: 17px;
    width: 17px;
    display: inline-block;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    &:hover{
        color: #7F8C95;
    }
  }
  /*---- DropdownBox Css Start ------*/
  .dropdown-box{
    &.show{
      .dropdown-toggle {
        background: ${(props) => props?.theme?.colors?.white};
        border: 1px solid #CFD5D8;
        color: ${(props) => props?.theme?.colors?.darkGray};
      }
    }
    .dropdown-toggle {
      height: 36px;
      background: ${(props) => props?.theme?.colors?.white};
      border: 1px solid #CFD5D8;
      color: ${(props) => props?.theme?.colors?.darkGray};
      border-radius: 3px;
      width: 100%;
      box-shadow: none !important;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      &:focus{
      }
      span{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F1F4F6;
        border-radius: 0px 3px 3px 0px;
        position: absolute;
        right: 1px;
        top: 1px;
        bottom: 1px;
        height: calc(100% - 2px);
        width: 36px;
        border-left: 1px solid #CFD5D8;
        i{
          height: 10px;
          width: 10px;
          display: inline-block;
          color: ${(props) => props?.theme?.colors?.blue}
        }
      }
      &:after{
        display: none;
      }
    }
    .dropdown-menu{
      width: 100%;
      border: 1px solid #CFD5D8;
      padding: 5px 0px;
      .dropdown-item{
        font-size: 14px;
        line-height: 19px;
        padding: 7px 10px;
        color: ${(props) => props?.theme?.colors?.darkGray};
        &:active{
          background: #e9ecef;
          color: ${(props) => props?.theme?.colors?.darkGray};
        }
      }
    }
  }
  /*---- DropdownBox Css end ------*/

  /*----- Modal css Start -----*/
  .modal-backdrop{
    background: rgba(37, 30, 49, 0.47);
    backdrop-filter: blur(7px);
    opacity: 1 !important;
  }
  .common-modal {
    .modal-content{
      border: none;
      box-shadow: 0px 40px 60px rgba(48, 69, 91, 0.2);
      border-radius: 10px;
    }
    .modal-header{
      padding: 28px 30px 12px;
      border-bottom: none;
      .close-modal{
        padding: 0px;
        height: 24px;
        width: 24px;
        flex: 0 0 24px;
        padding: 3px;
        outline: none;
        box-shadow: none;
        border: none;
        transition: all 0.25s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
          background-color: #ebe6e6;
        }
        i{
          display: block;
          height: 80%;
          width: 80%;
          color: #363636;
        }
      }
      h2,
      .modal-title{
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: ${(props) => props?.theme?.colors?.blue};
      }
    }
    .modal-container{
      width: 1280px;
      max-width: 100%;
      margin: 0 auto;
    }
    .nav-container{
      max-width: calc(100% - 60px);
      .nav-tab{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #F1F4F6;
        button{
          padding-bottom: 20px;
          margin-bottom: -2px;
        }
        .toggle-checkbox{
          margin: 1px 8px 0px 0px;
        }
      }
      .hidden-map{
        display: flex;
        align-items: center;
        .reset-btn{
          padding: 5px 10px;
          margin-right: 10px;
          background-color: ${(props) => props?.theme?.colors?.purpleHover};
          border: none;
        }
      }
    }
    .modal-body{
      padding: 10px 30px 0px;
    }
    .modal-footer{
      padding-left: 0px;
      padding-right: 0px;
      border-top-color: ${(props) => props?.theme?.colors?.borderColor};
      .modal-container{
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .back-btn{
        color: ${(props) => props?.theme?.colors?.primary};
        transition: all 0.25s ease-in-out;
        &:hover{
          color: ${(props) => props?.theme?.colors?.blue};
        }
        i{
          width: 10px;
          height: 10px;
          flex: 0 0 10px;
          margin-top: -1px;
        }
      }
      .practice-close-modal{
        background-color: ${(props) => props?.theme?.colors?.purpleHover};
      }
      .spinner-small-div{
        height: auto;
      }
    }
    .confirmation-modal-btn{
      min-width: 125px;
      padding-left: 20px;
      padding-right: 20px;
      &.update-btn{
        &:hover{
          color: #6F7B84;
          background: rgba(79,109,141,0.1);
          border-color: #edf0f3;
        }
      }
      &.no-update-btn{
        &:hover{
          background-color: #9586AE;
          color: #ffffff;
        }
      }
    }
    .currency-field{
      padding-left: 10px;
      font-size: 14px;
    }
    .label{
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: ${(props) => props?.theme?.colors?.darkGray};
      margin-bottom: 6px;
    }
  }
  .edit-modal-box{
    .modal-dialog {
      max-width: 510px;
    }
    .modal-footer{
      margin: 0px 30px;
    }
  }
  .photo-block{
    flex: 0 0 150px;
    max-width: 150px;
    .user-logo-block{
      height: 150px;
      width: 150px;
      overflow: hidden;
      border-radius: 8px;
      position: relative;
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
      .user-img-logo{
        background-color: #f5f5f5;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          ${imgcover};
        }
      }
      span{
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        color: ${(props) => props?.theme?.colors?.white};
        position: absolute;
        inset: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(48,69,91,0.5);
        transition: all 0.25s ease-in-out;
      }
      .dummy-img{
        background: rgba(48, 69, 91, 0.5);
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        i{
          width: 40px;
          height: 40px;
          display: block;
          transition: all 0.25s ease-in-out;
        }
        span{
          margin-top: 0px;
        }
      }
      &:hover{
        .dummy-img{
          i{
            opacity: 0;
            visibility: hidden;
          }
        }
        .upload-logo{
          opacity: 1;
          visibility: visible;
        }
        span{
          opacity: 0;
          visibility: hidden;
        }
      }
      .upload-logo{
        background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        background: -o-linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: all 0.25s ease-in-out;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        input{
          position: absolute;
          top: 0px;
          left: -110px;
          right: 0px;
          bottom: 0px;
          height: 100%;
          cursor: pointer;
          opacity: 0;
        }
       
        i{
          width: 34px;
          height: 34px;
          display: block;
        }
      }
    }
    span{
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 15px;
      color: rgba(77, 77, 77, 0.8);
      display: block;
      margin-top: 16px;
    }
  }
  .edit-profile-modal{
    .modal-dialog {
      max-width: 700px;
    }
    .edit-block-right{
      flex: 1;
      padding-left: 36px;
    }
    .modal-footer{
      margin: 0px 30px;
    }
    
  }
  .form-row{
    display: flex;
    margin-bottom: 25px;
    width: 100%;
    .form-col{
      flex: 1;
      &:not(:last-child){
        margin-right: 10px;
      }
      .category-add{
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }
    }
        
    .view-mode-row{
      display: flex;
      align-items: center;
      label{
        margin-right: 10px;
      }
      span{
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #4D4D4D;
      }
    }
    h2{
      flex: 0 0 100%;
      max-width: 100%;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      color: ${(props) => props?.theme?.colors?.blue};
      margin-bottom: 13px;
    }
  }
  .inputfield{
    background: ${(props) => props?.theme?.colors?.white};
    border: 1px solid #CFD5D8;
    border-radius: 3px;
    height: 36px;
  }
  .confirmation-modal{
    .modal-dialog{
      max-width: 510px;
    }
    .confirmation-info{
      margin-bottom: 30px;
      text-align: center;
    }
    h2{
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: ${(props) => props?.theme?.colors?.blue};
        margin-bottom: 9px;
      }
      span{
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: ${(props) => props?.theme?.colors?.darkGray};
      }
    .archive-btn{
      color: #6F7B84;
      background: rgba(79, 109, 141, 0.1);
      border: none;
      &:hover {
        background: rgba(79, 109, 141, 0.25);
      }
    }
    .mapping-confirmation{
      i{
        width: 80px;
        height: 80px;
        display: block;
        margin: 0px auto 4px;
        color: ${(props) => props?.theme?.colors?.purpleHover};
      }
      h2{
        margin-bottom: 30px;
      }
      span{
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #4D4D4D;
        span{
          font-weight: 700;
          margin: 0px 4px;
        }
      }
    }
    .delete-btn{
      color: #D35662;
      background: rgba(232, 113, 96, 0.15);
      border: none;
      &:hover {
        background: rgba(232, 113, 96, 0.3 );
      }
    }
    .delete-icon{
      height: 66px;
      width: 66px;
      display: inline-block;
      margin-bottom: 24px;
      color: #D35662;
      path{
        stroke-width: 0.6;
      }
    }
    .archive-icon{
      height: 58px;
      width: 66px;
      display: inline-block;
      margin-bottom: 24px;
      color: #6F7B84;
    }
    .active-icon{
      height: 58px;
      width: 58px;
      display: inline-block;
      margin-bottom: 24px;
      color: #6F7B84;
    }
  }
  .add-industry-modal{
    .modal-dialog {
      max-width: 750px;
    }
    .industry-type-name{
      flex: 0 0 316px;
      max-width: 316px;
      span{
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: ${(props) => props?.theme?.colors?.darkGray};
        display: block;
      }
      .error-span{
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #EF9898;
      }
    }
    .industry-row{
      margin-bottom: 15px;
    }
  }
 
  .edit-user-profile-modal{
    .modal-body{
      padding-bottom: 25px;
    }
    .role-btn-block{
      display: flex;
      .role-btn{
        &:not(:last-child){
          margin-right: 20px;
        }
      }
    }
  }
  .practice-mapping-modal{
    ~ div{
      z-index: 10051;
    }
    .modal-dialog{
      align-items: flex-start;  
    }
    .modal-body{
      padding: 0px;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }
    .modal-dialog {
      max-width: 100%;
      height: 100vh;
      margin: 0px;
    }
    .modal-content{
      height: 100vh;
      border-radius: 0px;
    }
    .modal-footer{
      margin: 0px 30px;
    }
    .year-industry-list-block{
      .modal-footer{
        margin: 0px;
      }
    }
    .modal-container{
      width: 1500px;
    }
    .modal-header {
      .header-left{
        overflow: hidden;
        flex: 1;
        padding-right: 20px;
        span{
          margin-bottom: 2px;
          display: block;
          font-weight: bold;
          font-size: 11px;
          line-height: 15px;
          text-transform: capitalize;
          color: #6F7B84;
        }
        .modal-title{
          font-weight: bold;
          font-size: 21px;
          line-height: 150%;
          color: ${(props) => props?.theme?.colors?.blue};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          max-width: 100%;
        }
      }
      .header-right{
        .refresh-btn{
          margin-right: 6px;
          width: 38px;
          height: 38px;
          border: 1px solid #CFD5D8;
          border-radius: 32px;
          i{
            height: 20px;
            width: 20px;
          }
        }
        .search-box{
          width: 350px;
        }
      }
    }
    .practice-setting-block{
      padding: 0px 30px;
    }
    .industry-area-block{
      padding: 0px 30px;
      flex: 1;
      overflow: hidden;
      .year-industry-list-block{
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .year-industry-list{
        flex: 1;
        margin: 0px -30px;
        padding: 0px 30px 30px 30px;
        overflow-y: auto;
        ${vscroll};
      }
    }
    form{
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      overflow: hidden;
      .practice-setting-block{
        flex: 1;
        ${vscroll};
        overflow-y: auto;
        padding-top: 30px;
        .practice-setting-row{
          width: 775px;
          max-width: 100%;
          margin: auto;
        }
        .practices-area-block{
          width: 100%;
        }
        .practice-setting-fieldblock{
          padding-top: 0px;
        }
      }
    }
  }
  .account-listing{
    padding: 0px 30px 0px;
    flex: 1;
    overflow-y: overlay;
    ${vscroll};
    .account-row{
      display: flex;
      align-items: center;
      padding: 3px 26px;
      border-radius: 5px;
      transition: all 0.25s ease-in-out;
      max-height: 10rem;
      &:hover{
        background: #F1F4F6;
        .viewoff-btn{
          opacity: 1;
          visibility: visible;
        }
      }
      &.removable_item{
        max-height: 0px;
        overflow: hidden;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .account-list-group{
      margin-bottom: 8px;
      max-height: 60vh;
      ${vscroll};
      overflow-y: auto;
      &:last-child{
        .account-list-body{
          padding-bottom: 5px;
        }
      }
    }
    .account-list-header{
      padding: 20px 0px 10px;
      position: sticky;
      top: 0;
      z-index: 11;
      background: #fff;
      border-bottom: 1px solid ${(props) => props?.theme?.colors?.borderColor};
      margin-bottom: 10px;
      .account-row{
        margin-bottom: 0px;
        &:hover{
          background: transparent;
        }
      }
      .dropdown-col{
        > div{
          width: auto;
        }
      }
      .account-col{
        display: flex;
        align-items: center;
        .label {
          font-weight: 700;
          display: flex;
          margin: 0px 10px 0px 0px;
          i{
            flex: 17px;
            max-width: 17px;
            width: 17px;
            margin-left: 13px;
            cursor: pointer;
            color: #C4C4C4;
            &.ascending{
              svg{
                path{
                  &:first-child{
                    color: #4D4D4D;
                  }
                }
              }
            }
            &.descending{
              svg{
                path{
                  &:last-child{
                    color: #4D4D4D;
                  }
                }
              }
            }
          }
        }
      }
      .action-col{
        flex: 0 0 75px;
        max-width: 75px;
      }
      .sorting-btn{
        margin-left: 10px;
        i{
          height: 100%;
          width: 100%;
          color: #C4C4C4;
          transition: all 0.25s ease-in-out;
        }
        &:hover{
          i{
            color: ${(props) => props?.theme?.colors?.darkGray};
          }
        }
      }
      .account-btn{
        font-weight: 700;
        color: ${(props) => props?.theme?.colors?.darkGray};
      }
      .unmapp-account{
        color: #9586AE;
      }
      .mapp-account{
        color: #9DD682;
      }
    }
    .account-list-body{
      padding: 0px 0px 20px;
      min-height: 350px;
      .action-col{
        flex: 0 0 100px;
        max-width: 100px;
        padding: 0px;
      }
      .unmap-action-col{
        flex: 0 0 62px;
        max-width: 62px;
        .check-btn{
          &:last-child{
            margin-right: 0px;
          }
        }
      }
    }
    .account-col{
      padding-left: 15px;
      padding-right: 15px;
    }
    .label-col{
      /* flex: 0 0 160px;
      max-width: 160px; */
      flex: 0 0 20%;
      max-width: 20%;
      padding-left: 7px;
      label{
        margin-bottom: 0px;
      }
    }
    .dropdown-col{
      /* flex: 0 0 230px;
      max-width: 230px; */
      flex: 0 0 20%;
      max-width: 20%;
      > div{
        width: 267px;
        max-width: 100%;
      }
    }
    /* .unmap-dropdown-col{
      flex: 0 0 calc(24.20% + 170px);
      max-width: calc(24.20% + 170px);
    } */
    .category-col{
      flex: 0 0 155px;
      max-width: 155px;
    }
    .type-col{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      label{
        margin-bottom: 0px;
      }
    }
    .ebitda-col{
      flex: 0 0 130px;
      max-width: 130px;
      .toggle-checkbox{
        margin-right: 15px;
      }
      > div{
        justify-content: space-between;
      }
    }
    .viewoff-btn{
      opacity: 0;
      visibility: hidden;
      transition: all 0.25s ease-in-out;
      color: #C4C4C4;
      max-width: 28px;
      height: 28px;
      width: 28px;
      flex: 0 0 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;
      i{
        max-width: 20px;
        height: 20px;
        width: 20px;
        flex: 0 0 20px;
      }
      &:hover{
        color: ${(props) => props?.theme?.colors?.darkGray};
      }
      i{
        height: 100%;
        width: 100%;
      }
      &.hidden-view-btn{
        opacity: 1;
        visibility: visible;
        color: ${(props) => props?.theme?.colors?.darkGray};
        &:hover{
          color: #C4C4C4;
        }
      }
    }
  }
  .assign-practice-modal{
    .modal-dialog {
      max-width: 696px;
    }
    .modal-header{
      align-items: start;
    }
    .header-left{
      .modal-title{
        margin-bottom: 3px;
      }
      span{
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: ${(props) => props?.theme?.colors?.darkGray};
        display: block;
      }
    }
    .client-staff{
      margin-left: 28px;
    }
  }
  .step-block{
    margin-bottom: 15px;
    .modal-container{
      max-width: calc(100% - 60px);
    }
    ul{
      overflow: hidden;
      position: relative;
      display: flex;
      width: 100%;
      justify-content: center;
      &:after{
        height: 4px;
        border-radius: 3px;
        background-color: #F1F4F6;
        position: absolute;
        top: 9px;
        width: 100%;
        content: '';
      }
      &:before{
        height: 4px;
        border-radius: 3px;
        background-color: #9DD682;
        position: absolute;
        top: 9px;
        width: 100%;
        content: '';
        z-index: 1;
      }
      li{
        position: relative;
        z-index: 1;
        text-align: center;
        flex:0 0 20%;
        max-width: 20%;
        .check-icon{
          height: 22px;
          width: 22px;
          border-radius: 25px;
          margin: auto auto 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #9DD682;
          border: 4px solid #9DD682;
          position: relative;
          z-index: 1;
        }
        i{
          height: 12px;
          width: 12px;
          color: ${(props) => props?.theme?.colors?.white};
          display: block;
          svg{
            *{
              stroke-width: 3px;
            }
          }
        }
        .step-text{
          font-weight: bold;
          font-size: 11px;
          line-height: 15px;
          text-transform: capitalize;
          color: #6F7B84;
        }
        &:after{
          background-color: #F1F4F6;
          /* border-radius: 3px; */
          right: -50%;
          position: absolute;
          height: 4px;
          top: 9px;
          width: 0%;
          content: '';
          transition: all 0.25s ease-in-out;
        }
        &:last-child{
          &:after{
            right: -50%;
          }
        }
        &.active{
          &:after{
            width: 100%
          }
          .check-icon{
            border-color: #9DD682;
            background-color: ${(props) => props?.theme?.colors?.white};
          }
          ~ li{
            &:after{
              width: 100%
            }
            .check-icon{
              border-color: #F1F4F6;
              background-color: ${(props) => props?.theme?.colors?.white};
            }
          }
        }
      }
    }
  }
  .practice-setting-block{
    .practice-setting-row{
      display: flex;
      .practice-setting-fieldblock{
        flex: 1;
        padding-top: 22px;
        padding: 0px 0px 0px 40px;
      }
    }
  }
  .year-industry-list-block{
    padding: 25px 0px 0px;
    .year-industry-title{
      margin-bottom: 10px;
    }
    .year-industry-list{
      /* margin-bottom: 25px; */
      .btn{
        height: 40px;
        width: 100%;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        color: ${(props) => props?.theme?.colors?.darkGray};
        transition: all 0.25s ease-in-out;
        border: none;
        margin-bottom: 3px;
        box-shadow: none !important;
      }
      .new-industry-btn{
        background-color: transparent;
        border: 1px solid #EEF0F2;
      }
      .old-industry-btn{
        background-color: #F7F8F9;
        &:hover{
          background-color: #EEF0F2;
        }
      }
    }
  }
  .select-avg{
    padding-top: 15px;
    .select-year-industry{
      margin-bottom: 7px;
      .select-year-industry-left{
        .back-btn{
          font-weight: bold;
          font-size: 11px;
          line-height: 15px;
          text-transform: capitalize;
          color: #6F7B84;
          margin-bottom: 2px;
          i{
            width: 10px;
            height: 10px;
            max-width: 10px;
            margin-right: 5px;
          }
        }
        .year-field{
          width: 210px;
          margin-top: 15px;
        }
      }
      .select-year-industry-right{

      }
    }
  }
  .edit-practice-modal{
    .modal-dialog{
      max-width: 780px;
    }
    .modal-footer{
      margin: 0px;
    }
    .form-row-col-33{
      margin-left: -5px;
      margin-right: -5px;
      width: calc(100% + 10px);
      .form-col{
        padding: 0px 5px;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        width: 33.33%;
        margin: 0px;
      }
    }
    .practice-address-inner-block{
      display: flex;
    }
    .practice-address-block{
      .form-row{
        flex: 0 0 33.33%;
        max-width: 33.33%;
        width: 33.33%;
        .form-col{
          flex: 0 0 calc(100% - 10px);
          max-width: calc(100% - 10px);
          width: calc(100% - 10px);
          margin-right: 10px;
        }
      }
      .practices-area{
        flex: 0 0 66.67%;
        max-width: 66.67%;
        width: 66.67%;
        display: flex;
        justify-content: space-between;
        .form-col{
          flex: 0 0 calc(50% - 5px);
          max-width: calc(50% - 5px);
          width: calc(50% - 5px);
          margin-right: 10px;
        }
      }
      .practices-area-block{
        display: flex;
      }
    }
  }
  .account-edit-list-block{
    > div{
      &:not(:last-child){
        margin-bottom: 4px;
      }
    }
  }
  /*----- Modal css End -----*/

  .error{
    input{
      border: 2px solid #EF9898 !important;
    }
  }
  .error-span{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #EF9898;
  }
  .tooltip{
    &.show{
      opacity: 1;
    }
    .tooltip-arrow {
      height: 5px;
      width: 10px;
    }
    &.bs-tooltip-top{
      .tooltip-arrow {
        &:before{
          top: -2px;
          border-width: 6px 6px 0px;
          border-top: 6px solid ${(props) => props?.theme?.colors?.blue};
        }
      }
    }
    &.bs-tooltip-bottom{
      .tooltip-arrow {
        &:before{
          bottom: -1px;
          border-width: 0 5px 5px;
          border-bottom: 5px solid ${(props) => props?.theme?.colors?.blue};
        }
      }
    }
    .tooltip-inner{
      background: ${(props) => props?.theme?.colors?.blue};
      box-shadow: 0px 3px 16px rgba(100, 138, 177, 0.25);
      border-radius: 4px;
      padding: 8px 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: ${(props) => props?.theme?.colors?.white};
    }
    &.box-info-tooltip{
      .tooltip-inner{
        max-width: inherit;
      }
    }
    &.month-tooltip{
      .tooltip-inner{
        span{
          display: block;
          text-align: left;
        }
        .collection-month{
          margin-top: 5px;
          span{
            margin-right: 15px;
            display: inline-block;
          }
        }
      }
    }
    &.stack-tooltip{
      .tooltip-inner{
        font-weight: 500;
        span{
          display: inline;
          font-weight: 700;
        }
      }
    }
  }
  .percentology-table {
    tbody{
      .empty-tr{
        &:hover{
          background-color: transparent;
        }
        .empty-td{
          border-bottom: none;
          font-size: 14px;
          line-height: 19px;
          color: #4D4D4D;
        }
        .empty-box-desc{
          height: calc(100vh - 320px);
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          Button{
            width: auto;
            padding: 0px 20px;
            margin-top: 16px;
            i{
              width: 13px;
              height: 13px;
              display: inline-block;
              margin-right: 8px;
            }
          }
          .empty-icon{
            width: 84px;
            height: 84px;
            display: inline-block;
            color: #71628A;
            margin: 0 auto 25px;
          }
          .empty-msg{
            width: 345px;
            margin: 0 auto;
            span{
              display: block;
              text-align: center;
            }
          }
        }
        .empty-box-desc-table{
          height: calc(100vh - 200px);
        }
      }
      .loader-tr{
        &:hover{
            background-color: transparent;
        }
        td{
            border: none;
        }
        .spinner-block{
          height: calc(100vh - 280px);
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
        }
      }
    }
  }
  /* .search-box{
    position: relative;
    .loader-icon{
      height: 21px;
      width: 21px;
      display: block;
      color: ${(props) => props?.theme?.colors?.primary};
    }
  } */
  .datepicker{
    position: relative;
    .calendar-icon{
      position: absolute;
      height: 20px;
      width: 20px;
      color: #7E7E7E;
      right: 8px;
      top: 8px;
    }
    input{
      background: transparent;
      border: 1px solid #CFD5D8;
      height: 36px;
      width: 100%;
      border-radius: 4px;
      padding: 9px 10px;
      font-weight: 400;
      font-size: 13px;
      line-height: 29px;
      color: ${props => props?.theme?.colors?.darkGray};
      transition: all 0.25s ease-in-out;
      &::-webkit-input-placeholder {color: rgba(77, 77, 77, 0.4);}
      &::-moz-placeholder {color: rgba(77, 77, 77, 0.4);}
      &:-ms-input-placeholder {color: rgba(77, 77, 77, 0.4);}
      &:-moz-placeholder {color: rgba(77, 77, 77, 0.4);}
      position: relative;
      z-index: 1;
      &:focus{
        border-color: rgba(48, 69, 91, 50%);
      }
      &.error{
          border-color: #EF9898 !important;
      }
    }
    .react-datepicker__tab-loop{
      .react-datepicker-popper{
        padding: 0px;
        z-index: 101;
      }
      .react-datepicker__triangle{
        display: none;
      }
      .react-datepicker{
        border: 1px solid ${props => props?.theme?.colors?.purpleLight};
        box-shadow: 0px 5px 10px rgba(0,0,0,0.15);
      }
      .react-datepicker__month{
        .react-datepicker__week{

        }
        .react-datepicker__day{
          font-size: 13px;
          color: ${props => props?.theme?.colors?.blue};
          &:hover{
            background-color: #F1F4F6;
          }
        }
        .react-datepicker__day--keyboard-selected,
        .react-datepicker__day--selected{
          color: ${props => props?.theme?.colors?.white};
          background: ${props => props?.theme?.colors?.purpleLight};
        }
      }
      .react-datepicker__header {
        background: ${props => props?.theme?.colors?.purpleLight};
        border: none;
        border-radius: 0px;
        color: ${props => props?.theme?.colors?.white};
        .react-datepicker__current-month{
          color: ${props => props?.theme?.colors?.white};
        }
        .react-datepicker__day-names{
          .react-datepicker__day-name{
            color: ${props => props?.theme?.colors?.white};
          }  
        }
      }
      .react-datepicker__year{
        margin: 0px;
        .react-datepicker__year-wrapper{
          padding: 10px 5px;
          .react-datepicker__year-text{
            display: inline-block;
            max-width: calc(50% - 10px);
            flex: 0 0 calc(50% - 10px);
            padding: 5px;
            margin: 1px 5px;
          }
          .react-datepicker__year-text--selected{
            background-color: ${props => props?.theme?.colors?.purpleLight};
          }
          .react-datepicker__year-text--keyboard-selected{
            background-color: ${props => props?.theme?.colors?.purpleLight};
          }
        }
      }
      .react-datepicker__navigation{
        top: 9px;
        .react-datepicker__navigation-icon{
          &::before{
            border-color: ${props => props?.theme?.colors?.white};
          }
        }
      }
      .react-datepicker__header__dropdown{
        margin-top: 5px;
      }
      select{
        width: 100%;
        height: 26px;
        background-color: ${props => props?.theme?.colors?.white};
        color: ${props => props?.theme?.colors?.blue};
        padding: 2px 8px;
        font-size: 14px;
        border: none;
        border: 1px solid ${props => props?.theme?.colors?.bodyLight};
        box-sizing: border-box;
        cursor: pointer;
        border-radius: 4px;
        -webkit-appearance: none;
        -moz-appearance:    none;
        appearance:         none;
        background-image: url(${DownArrow});
        background-repeat: no-repeat;
        background-size: 10px auto;
        background-position: right 8px center;
        padding-right: 30px;
        option {
            color: ${props => props?.theme?.colors?.black};
            background: ${props => props?.theme?.colors?.white};
            white-space: pre;
            min-height: 20px;
            padding: 0px 2px 1px;
        }
      }
    }
  }
  .modal{
     + div{
      z-index: 1060;
    }
    .focus-year{
      height: 110px;
      resize: none;
    }
  }
  .Toastify{
    .Toastify__toast{
      padding: 20px;
      box-shadow: 0px 20px 60px rgba(48, 69, 91, 0.3);
      border-radius: 10px;
    }
    .Toastify__toast--success{
      background-color: #4BBBCE;
    }
    .Toastify__toast-body{
      padding: 0px;
    }
    .Toastify__close-button{
      height: 16px;
      width: 16px;
      display: block;
      margin-top: 4px;
      svg{
        height: 100%;
        width: 100%;
      }
    }
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  .avg-tooltip{
    .tooltip-inner{
      max-width: 345px;
      text-align: left;
      padding: 20px;
    }
  }
  .avg-tooltip-box{
    p{
      margin-top: 6px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: ${(props) => props?.theme?.colors?.white};
    }
    ul{
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      li{
        display: flex;
        align-items: flex-start;
        &:not(:last-child){
          margin-bottom: 3px;
        }
        .avg-value-box{
          flex: 0 0 90px;
          max-width: 90px;
          display: flex;
          align-items: center;
          span{
            flex: 1;
            text-align: center;
          }
          .avg-value{
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${(props) => props?.theme?.colors?.white};
            flex: 0 0 37px;
            max-width: 37px;
          }
        }
        .avg-name{
          padding-left: 10px;
          font-weight: 400;
          font-size: 13px;
          line-height: 160%;
          text-transform: capitalize;
          color: ${(props) => props?.theme?.colors?.white};
        }
      }
    }
  }
  .admin-practices-box{
    .tooltip-inner{
      max-width: 225px;
      width: 225px;
      text-align: left;
    }
    .admin-practices-inner-box{
      padding-left: 15px;
      li{
        list-style: disc;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.015em;
        color: ${(props) => props?.theme?.colors?.white};
        &:not(:last-child){
          margin-bottom: 4px;
        }
      }
    }
  }
  .linechart{
  align-items: center;   
  text-align: center;
 }
 .linechart *{
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
 }

 .barchart *{
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
 }
 .bs-tooltip-end{
  .tooltip-arrow{
    height: 10px;
    width: 5px;
    left: 1px;
    &:before{
      left: 0px;
      right: auto;
      border-width: 5px 5px 5px 0px;
    }
  }
 }
 .bs-tooltip-start{
  .tooltip-arrow{
    height: 10px;
    width: 5px;
    right: 1px;
    &:before{
      right: 0px;
      left: auto;
      border-width: 5px 0px 5px 5px ;
      border-left-color: #30455B;
    }
  }
 }
 .email-view-row{
  display: flex;
  align-items: center;
    label{
      margin-right: 10px;
    }
    .email-view{
      display: block;      
    }
    &.view-mode-row{
      margin-bottom: 10px;
    }
  }

  .no-chart-data-modal{
    .modal-dialog {
      max-width: 1000px;
      width: calc(100% - 30px);
    }
    .no-data-contianer{  
      .no-data{
        padding: 40px 0px;
        text-align: center;
        i{
          display: flex;
          justify-content: center;
          margin-bottom: 25px;
        }
        i > svg {
          width: 42px;
          height: 42px;
        }
        h1{
          font-weight: 700;
          font-size: 20px;
          display: flex;
          align-items: center;
          text-transform: capitalize;
          color: #30455B;
          display: flex;
          justify-content: center;
          margin-bottom: 8px;
        }
        p{
          font-weight: 600;
          font-size: 16px;
          display: flex;
          align-items: center;
          color: #ADADAD;
          display: flex;
          justify-content: center;
          width: 100%;
          margin-bottom: 30px;
      }
    }
  }
  }
  .practices-info{
    ul li {
      display: flex;
      align-items: center;
      &:not(:last-child){
        margin-bottom: 3px;
      }
      i.practice-status{
        width: 8px;
        height: 8px;
        border-radius: 2px;
        margin-right: 10px;
        background: #ccc;
        display: block;
        &.active{
          background: #4fb120;
        }
      }
    }
  }
  .report-modal{
    .modal-dialog{
      max-width: 854px;
    }
    .modal-content{
      border: none;
      overflow: hidden;
    }
  }
  .chart-account-modal{
    .modal-header{
        padding: 20px 20px 25px;
    }
    .modal-body{
        padding: 0px;
    }
  }
  .preset-modal-block{
    .modal-dialog{
      max-width: 1000px;
    }
    .modal-footer{
      padding-right: 20px;
      padding-left: 20px;
    }
    .preset-radion-group{
      display: flex;
      height: 36px;
      align-items: center;
      > div{
        &:not(:last-child){
          margin-right: 18px;
        }
      }
    }
    .preset-name-col{
      flex: 0 0 370px;
      max-width: 370px;
      margin-right: 15px;
    }
    .type-col{
      margin-right: 15px;
      flex: 0 0 160px;
      max-width: 160px;
    }
    .preset-form-block{
      .form-row{
        display: flex;
      }
    }
    .preset-trends-table{
      .td-checkbox{
        > div{
          height: 18px;
          width: 18px;
        }
      }
      table{
        margin-bottom: 10px;
        th{
          color: #22252F;
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          padding: 9px 10px;
          vertical-align: bottom;
          border-bottom: 1px solid #EAEAEA;
          &:first-child{
            padding-left: 0px;
          }
          &:last-child{
            padding-right: 0px;
          }
          span{
            display: inline-block;
          }
        }
        td{
          padding: 9px 10px;
          border-bottom: 1px solid #EAEAEA;
          &:first-child{
            padding-left: 0px;
          }
          &:last-child{
            padding-right: 0px;
          }
          input[type="checkbox"]{
            &:disabled{
              cursor: default;
              ~ .check-icon{
                opacity: 0.5;
              }
            }
          }
        }
        .color-col{
          min-width: 70px;
          width: 70px;
          max-width: 70px;
        }
        .delete-btn{
          width: 20px;
          height: 20px;
          padding: 0px;
          color: #E87160;
          i{
            height: 100%;
            width: 100%;
            margin: 0px;

          }
        }
        .action-col{
          padding: 0px;
          min-width: 50px;
          width: 50px;
          max-width: 50px;
        }
      }
    }
  }
  .month-income-tooltip{
    .tooltip-inner{
      max-width: 350px;
      padding: 18px 22px;
      h3{
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: ${(props) => props?.theme?.colors?.white};
        text-align: left;
      }
      p{
        font-size: 600;
        font-size: 12px;
        line-height: 16px;
        color: ${(props) => props?.theme?.colors?.white};
        margin-bottom: 10px;
        text-align: left;
      }
      .month-table-block{
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        padding: 5px 0px;
        table{
          td{
            padding: 5px 10px;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            color: ${(props) => props?.theme?.colors?.white};
            text-align: left;
            &:first-child{
              text-align: center;
              min-width: 115px;
              max-width: 115px;
              width: 115px;
            }
          }
        }
      }
    }
  }
  .annual-income-tooltip{
    .tooltip-inner{
      max-width: 320px;
      text-align: left;
      padding: 18px 22px;
      > div{
        &:not(:last-child){
          margin-bottom: 6px;
        }
      }
    }
  }
  .react-datepicker-popper{
    z-index: 200 !important;
  }
`;
export default GlobalStyle;
