// action related to userReducer
export const USER_ACTIONS = Object.freeze({
    SET_USER_DATA: 'SET_USER_DATA'

});

// reducer function for users
const userReducer = (state, action) => {
    try {
        const {
            type,
            payload
        } = action;

        switch (type) {
            case USER_ACTIONS.SET_USER_DATA:
                return {
                    ...state,
                    user: payload
                }
            case USER_ACTIONS.SET_PRACTICE_TYPE_DATA:
                return {
                    ...state,
                    practice_types: payload
                }
            default:
                throw new Error('Wrong type of action passed!');
        }
    } catch (error) {
        console.error({
            error
        });
    }
}

export default userReducer;