const URLS = {
  Login: "/login",
  AdminDashboard: '/admin/dashboard',
  AdminPractice: '/admin/practices',
  AdminPracticeType: '/admin/practice-type',
  AdminUser: '/admin/admin-users',
  Clients: '/admin/clients',
  AdminMapping: '/admin/category-mapping',
  AdminStandardRule: '/admin/mapping-rules',
  AdminIndustryAverage: '/admin/industry-averages',
  AdminBenchmarkReport: '/admin/benchmark-report',
  AdminPresetTrends: '/admin/preset-trends',
  AdminPageNotFound: '/admin/page-not-found',
  AdminProfile: '/admin/my-profile',
  AdminQuickbookConnection: '/quickbook/callback',
  Dashboard: '/dashboard',
  Trends: '/trends',
  Reports: '/reports',
  SelectPractice: '/select-practice',
  ForgotPassword: '/forgot-password',
  ResetPassword: '/reset-password/:reset_password_token',
  ClientProfile: '/my-profile',
  CoIndependentUsers: '/co-independent-users',

  // Register: "/register",
  // Logout: "/logout"
};

export const ClientURLs = [
  '/dashboard',
  '/trends',
  '/reports',
  '/select-practice',
  '/my-profile',
  '/co-independent-users',
];

export const AdminURLs = [
  '/admin/dashboard',
  '/admin/practices',
  '/admin/practice-type',
  '/admin/admin-users',
  '/admin/category-mapping',
  '/admin/clients',
  '/admin/mapping-rules',
  '/admin/industry-averages',
  '/admin/benchmark-report',
  '/quickbook/callback',
  '/admin/preset-trends',
  '/admin/my-profile',
]
export default URLS;
