import React from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SPINNER_VARIENT = {
  Spinnertable: 'spinner-table',
  Spinnerdiv: 'spinner-div',
  SpinnerSmalldiv: 'spinner-small-div',
  SpinnerSearch: 'spinner-search',
  SpinnerButton: "spinner-button"
}

function Spinner({ variant = 'spinner-table', size = 32, color = "#6A35FF", colSpan }) {
  switch (variant) {
    case SPINNER_VARIENT.Spinnertable:
      return (
        <tr className="loader-tr">
          <td className="p-0" colSpan={colSpan}>
            <div className="spinner-block">
              <SVGLoader
                className="loader"
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
                viewBox="0 0 24 24"
                fill="none"
                stroke="#30455B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10" />
              </SVGLoader>
            </div>
          </td>
        </tr>
      )
    case SPINNER_VARIENT.Spinnerdiv:
      return (
        <Spinneranim>
          <SVGLoader
            className="loader"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke="#30455B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
          </SVGLoader>
        </Spinneranim>
      )
    case SPINNER_VARIENT.SpinnerSmalldiv:
      return (
        <Spinneranim className="spinner-small-div">
          <SVGLoader
            className="loader"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke="#30455B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
          </SVGLoader>
        </Spinneranim>
      )
    case SPINNER_VARIENT.SpinnerSearch:
      return (
        <SVGLoader
          className="loader"
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 24 24"
          fill="none"
          stroke="#30455B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
        </SVGLoader>
      )
    case SPINNER_VARIENT.SpinnerButton:
      return (
        <tr className="loader-tr">
          <td className="p-0" colSpan={colSpan}>
            <div className="spinner-block">
              <SVGLoader
                className="loader"
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10" />
              </SVGLoader>
            </div>
          </td>
        </tr>
      )
    default:
      break;
  }
}


export default Spinner;

const Spinneranim = styled.div`
  width: 100%;
  height: calc(100vh - 260px);
  display: flex;
  align-items: center;
  justify-content: center;
  &.spinner-small-div{
    height: 200px;
    width: 100%;
  }  
`

const SVGLoader = styled.svg`
&.loader {
  animation: rotate 2s linear infinite;
  width: 30px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

&.loader circle {
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
`;

Spinner.propTypes = {
  optionalNumber: PropTypes.number,
  optionalString: PropTypes.string
}