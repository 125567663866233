import { USER_ROLES } from "constants/default-values";
import { NESTED_ADMIN_COMPONENTS, NESTED_CLIENT_COMPONENTS } from "constants/nested-components";
import * as PAGES from "./pages";
import URLS from "./urls";
import secureLocalStorage from "react-secure-storage";

// List of protected routes
export const PROTECTED_ROUTES = [
   {
      key: 'admin-dashboard',
      path: URLS.AdminDashboard,
      render: (props) => <PAGES.AdminDashboard {...props} activeComponentKey={NESTED_ADMIN_COMPONENTS.Practices} />,
      exact: true
   },
   {
      key: 'admin-practice',
      path: URLS.AdminPractice,
      render: (props) => <PAGES.AdminDashboard {...props} activeComponentKey={NESTED_ADMIN_COMPONENTS.Practices} />,
      exact: true
   },
   {
      key: 'admin-user',
      path: URLS.AdminUser,
      render: (props) => <PAGES.AdminDashboard {...props} activeComponentKey={NESTED_ADMIN_COMPONENTS.Users} />,
      exact: true
   },
   {
      key: 'client-user',
      path: URLS.Clients,
      render: (props) => <PAGES.AdminDashboard {...props} activeComponentKey={NESTED_ADMIN_COMPONENTS.Clients} />,
      exact: true
   },
   {
      key: 'admin-practice-types',
      path: URLS.AdminPracticeType,
      render: (props) => <PAGES.AdminDashboard {...props} activeComponentKey={NESTED_ADMIN_COMPONENTS.PracticeType} />,
      exact: true
   },
   {
      key: 'admin-mapping',
      path: URLS.AdminStandardRule,
      render: (props) => <PAGES.AdminDashboard {...props} activeComponentKey={NESTED_ADMIN_COMPONENTS.Mapping} />,
      exact: true
   },
   {
      key: 'admin-mapping-rules',
      path: URLS.AdminMapping,
      render: (props) => <PAGES.AdminDashboard {...props} activeComponentKey={NESTED_ADMIN_COMPONENTS.MappingRule} />,
      exact: true
   },
   {
      key: 'admin-industry-averages',
      path: URLS.AdminIndustryAverage,
      render: (props) => <PAGES.AdminDashboard {...props} activeComponentKey={NESTED_ADMIN_COMPONENTS.IndustryAverage} />,
      exact: true
   },
   {
      key: 'admin-benchmark-report',
      path: URLS.AdminBenchmarkReport,
      render: (props) => <PAGES.AdminDashboard {...props} activeComponentKey={NESTED_ADMIN_COMPONENTS.BenchmarkReport} />,
      exact: true
   },
   {
      key: 'admin-preset-trends',
      path: URLS.AdminPresetTrends,
      render: (props) => <PAGES.AdminDashboard {...props} activeComponentKey={NESTED_ADMIN_COMPONENTS.PresetTrends} />,
      exact: true
   },
   // {
   //    key: 'admin-quickbook-connection',
   //    path: URLS.AdminQuickbookConnection,
   //    render: (props) => <PAGES.AdminDashboard {...props} activeComponentKey={NESTED_ADMIN_COMPONENTS.QuickbookConnection} />,
   //    exact: true
   // },
   {
      key: 'quickbook-connection',
      path: URLS.AdminQuickbookConnection,
      render: (props) => <PAGES.QiuckBookCallback {...props} activeComponentKey={NESTED_ADMIN_COMPONENTS.QuickbookConnection} />,
      exact: true
   },
   {
      key: 'admin-page-not-found',
      path: URLS.AdminPageNotFound,
      render: (props) => <PAGES.AdminDashboard {...props} activeComponentKey={NESTED_ADMIN_COMPONENTS.Practices} />,
      exact: true
   },
   {
      key: 'admin-profile',
      path: URLS.AdminProfile,
      render: (props) => <PAGES.AdminDashboard {...props} activeComponentKey={NESTED_ADMIN_COMPONENTS.AdminProfile} />,
      exact: true
   },
   {
      key: 'select-practice',
      path: URLS.SelectPractice,
      render: (props) => <PAGES.Dashboard {...props} activeComponentKey={NESTED_CLIENT_COMPONENTS.SelectPractice} />,
      exact: true
   },
   {
      key: 'dashboard',
      path: URLS.Dashboard,
      render: (props) => <PAGES.Dashboard {...props} activeComponentKey={NESTED_CLIENT_COMPONENTS.Dashboard} />,
      exact: true
   },
   {
      key: 'trends',
      path: URLS.Trends,
      render: (props) => <PAGES.Dashboard {...props} activeComponentKey={NESTED_CLIENT_COMPONENTS.Trends} />,
      exact: true
   },
   {
      key: 'reports',
      path: URLS.Reports,
      render: (props) => <PAGES.Dashboard {...props} activeComponentKey={NESTED_CLIENT_COMPONENTS.Reports} />,
      exact: true
   },
   {
      key: 'client-profile',
      path: URLS.ClientProfile,
      render: (props) => <PAGES.Dashboard {...props} activeComponentKey={NESTED_CLIENT_COMPONENTS.ClientProfile} />,
      exact: true
   },
   {
      key: 'co-independent-users',
      path: URLS.CoIndependentUsers,
      render: (props) => <PAGES.Dashboard {...props} activeComponentKey={NESTED_CLIENT_COMPONENTS.CoIndependentUsers} />,
      exact: true
   }
];

// List of public routes
export const PUBLIC_ROUTES = [
   {
      key: `login`,
      path: URLS.Login,
      render: (props) => <PAGES.Login {...props} />,
      exact: true,
   },
   {
      key: `forgot-password`,
      path: URLS.ForgotPassword,
      render: (props) => <PAGES.ForgotPassword {...props} />,
      exact: true,
   },
   {
      key: `reset-password`,
      path: URLS.ResetPassword,
      render: (props) => <PAGES.ResetPassword {...props} />,
      exact: true,
   }
   // {
   //    key: `register`,
   //    path: URLS.Register,
   //    render: (props) => <PAGES.Register {...props} />,
   //    exact: true,
   // },
   // {
   //    key: `logout`,
   //    path: URLS.Logout,
   //    render: (props) => <PAGES.Logout {...props} />,
   //    exact: true,
   // },
   // {
   //    key: `page-not-found`,
   //    path: URLS.PageNotFound,
   //    render: (props) => <PAGES.PageNotFound {...props} />,
   //    exact: false,
   // },
];

export const defaultRoute = () => {
   const currentUser = JSON.parse(secureLocalStorage.getItem('currentUser'))
   const selectedPractice = JSON.parse(secureLocalStorage.getItem('selectedPractice'))
   let path = null;
   if (currentUser && Object.keys(currentUser)?.length) {
      switch (currentUser.role) {
         case USER_ROLES.admin:
         case USER_ROLES.staff:
            path = selectedPractice && Object.keys(selectedPractice)?.length ? URLS.Dashboard : URLS.AdminDashboard
            break;
         case USER_ROLES.client:
         case USER_ROLES.client_staff:
         case USER_ROLES.independent:
         case USER_ROLES.co_independent:
            path = selectedPractice && Object.keys(selectedPractice)?.length ? URLS.Dashboard : URLS.SelectPractice
            break;
         default:
            path = window.location
            break;
      }
   } else {
      path = URLS.Login;
   }
   return path
}
