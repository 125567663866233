import { lazy } from "react";

// component responsible for login
export const Login = lazy(() => import(
    /* webpackChunkName: "Login" */
    './../pages/Login'
)
.catch(error => console.error({error}))
);

export const ForgotPassword = lazy(() => import(
    /* webpackChunkName: "Login" */
    './../pages/ForgotPassword'
)
.catch(error => console.error({error}))
);

export const ResetPassword = lazy(() => import(
    /* webpackChunkName: "Login" */
    './../pages/ResetPassword'
)
.catch(error => console.error({error}))
);

// component responsible for dasboard
export const AdminDashboard = lazy(() => import(
    /* webpackChunkName: "Dashboard" */
    './../pages/Admin/AdminDashboard'
)
.catch(error => console.error({error}))
);

export const Dashboard = lazy(() => import(
    /* webpackChunkName: "Dashboard" */
    './../pages/Client/Dashboard'
)
.catch(error => console.error({error}))
);

export const QiuckBookCallback = lazy(() => import(
    /* webpackChunkName: "Dashboard" */
    './../pages/QiuckBookCallback'
)
.catch(error => console.error({error}))
);
// // component responsible for register
// export const Register = lazy(() => import(
//     /* webpackChunkName: "Login" */
//     './../pages/Register'
// )
// .catch(error => console.error({error}))
// );
// // component responsible for page not foumd
// export const PageNotFound = lazy(() => import(
//     /* webpackChunkName: "PageNotFound" */
//     './../pages/PageNotFound'
// )
// .catch(error => console.error({error}))
// );

// // component responsible for logout
// export const Logout = lazy(() => import(
//     /* webpackChunkName: "PageNotFound" */
//     './../pages/Logout'
// )
// .catch(error => console.error({error}))
// );