const THEME = {
    colors: {
        // primary: '#6A35FF',
        primaryLight: '#ECE5FF',
        orange: '#FF6F26',
        orangeLight: '#FFEEE5',
        heading: '#2D3958',
        headingLight: '#969CAC',
        headingLighter: '#EBEEF5',
        body: '#546285',
        bodyLight: '#ECEEF3',
        error: '#F64E4C',
        errorLight: '#E87160',
        success: '#3AC772',
        successLight: '#E7F8EE',
        warning: '#FFD453',
        warningLight: '#FFF7E0',
        alert: '#00A3FF',
        alertLight:'#E0F4FF',
        disabled: '#9E9EB0',
        disabledLight: '#EEEEF1',
        black: '#000000',
        white: '#ffffff',
        // borderColor: '#E1E5EB',
        greyLight: '#F2F5F8',
        transparent: 'transparent',
        pagination: '#323A46',
        royalBlue: '#5375e2',
        // green: '#18c571',
        pink: '#d05dc7',
        whiteLight: '#fefefe',
        label: '#575F6A',
        greyText: '#728AA5',
        badgeColor: '#6882A0',
        hard: '#FD7474',
        yellow: '#FFBE33',
        blackDark: '#333333',
        blueLight: '#007BFF',
        blueBerry: '#874FFE',
        policeBlue: '#3F4E5E',


        primary: '#4F6D8D',
        blue: '#30455B',
        borderColor: '#EAEAEA',
        green: '#37A04F',
        darkGray: '#4D4D4D',
        failed: '#C81900',
        purpleDark: '#61478C',
        purpleLight: '#9586AE',
        purpleHover: '#71628a',
        darkBlack: '#333333',
        lightGreen: '#9DD682'
    },
    fonts: {
        primary: `'Nunito', sans-serif;`,
        default: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
    },
    defaultFontSize: 16,
};

export default THEME;