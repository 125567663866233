
export const USER_ROLES = {
    admin: 'admin',
    staff: 'staff',
    client: 'client',
    client_staff: 'client_staff',
    independent: "independent",
    co_independent: "co_independent",
}

export const USER_ROLES_LEBEL = {
    admin: 'Admin',
    staff: 'Staff',
    client: 'Client',
    client_staff: 'Staff',
    independent: "Independent",
    co_independent: "Co-Independent",
}

export const ACCOUNT_LIST = {
    unmapped: 'UnMapped',
    mapped: 'Mapped',
    hidden: 'Hidden'
}

export const ACCOUNT_TYPE_LIST = {
    profitLoss: 'profitLoss',
    balancesheet: 'balancesheet',
}

export const ACCOUNT_TYPE_LIST_TAB = [
    {
        id: 1,
        text: "P&L Accounts",
        tabType: ACCOUNT_TYPE_LIST.profitLoss
    },
    {
        id: 2,
        text: "Balance Sheet Accounts",
        tabType: ACCOUNT_TYPE_LIST.balancesheet
    },
]

export const ACCOUNT_LIST_TAB = [
    {
        id: 1,
        text: "Unmapped Accounts",
        status: 'unmapped',
        count: 0,
        tabType: ACCOUNT_LIST.unmapped
    },
    {
        id: 2,
        text: "Mapped Accounts",
        status: 'mapped',
        count: 0,
        tabType: ACCOUNT_LIST.mapped

    },
    // {
    //     id: 3,
    //     text: "Hidden",
    //     status:'hidden',
    //     count: 0,
    //     tabType: ACCOUNT_LIST.hidden
    // },
]

export const PRACTICE_STEPS = {
    quickbook_connection: 'Quickbook Connection',
    account_mapped: 'Mapping Account',
    practice_setting: 'Practice Setting',
    industry_average: 'Industry Average',
    doctor_compensation: 'Doctor Compensation',
    target: 'Target Benchmark Percents'
}

export const CHART_TYPES = {
    lineChart: 'line',
    areaChart: 'area',
    barChart: 'bar'

}

export const CHART_COLOR = ['#9586ae', '#30455b', '#9dd682', '#7bc2d4', '#fab651']
export const CHART_COLOR_PICKER_BLOCK = ['#9586ae', '#c9c2d6', '#9dd682', '#30455b', '#7bc2d4', '#fab651', '#ee8e94', '#afe3f0']
export const CHART_COLOR_SNAPSHOT = ['#EAEAEA', '#9dd682', '#9586ae', '#7bc2d4', '#ee8e94', "#FF6633",
    "#FF33FF", "#FFFF99", "#00B3E6", "#E6B333", "#3366E6", "#999966", "#99FF99", "#B34D4D",
    "#80B300", "#809900", "#E6B3B3", "#6680B3", "#66991A", "#FF99E6", "#CCFF1A", "#FF1A66", "#E6331A", "#33FFCC", "#66994D",
    "#B366CC", "#4D8000", "#B33300", "#CC80CC", "#66664D", "#991AFF", "#E666FF", "#4DB3FF", "#1AB399", "#E666B3", "#33991A",
    "#CC9999", "#B3B31A", "#00E680", "#4D8066", "#809980", "#E6FF80", "#1AFF33", "#999933", "#FF3380", "#CCCC00", "#66E64D",
    "#4D80CC", "#9900B3", "#E64D66", "#4DB380", "#FF4D4D", "#99E6E6", "#6666FF",]
export const CHART_COLOR_COLLECTION_INCOME = ['#30455b', '#9586ae', '#9dd682', '#7bc2d4', '#ee8e94', "#FF6633",
    "#FF33FF", "#FFFF99", "#00B3E6", "#E6B333", "#3366E6", "#999966", "#99FF99", "#B34D4D",
    "#80B300", "#809900", "#E6B3B3", "#6680B3", "#66991A", "#FF99E6", "#CCFF1A", "#FF1A66", "#E6331A", "#33FFCC", "#66994D",
    "#B366CC", "#4D8000", "#B33300", "#CC80CC", "#66664D", "#991AFF", "#E666FF", "#4DB3FF", "#1AB399", "#E666B3", "#33991A",
    "#CC9999", "#B3B31A", "#00E680", "#4D8066", "#809980", "#E6FF80", "#1AFF33", "#999933", "#FF3380", "#CCCC00", "#66E64D",
    "#4D80CC", "#9900B3", "#E64D66", "#4DB380", "#FF4D4D", "#99E6E6", "#6666FF",]

export const CHART_LEGENDS_NAMES = [
    "mainChart-0",
    "mainChart-1",
    "mainChart-2",
    "mainChart-3",
    "mainChart-4",
    "mainChart-5",
    "mainChart-6",
    "mainChart-7",
    "mainChart-8",
    "mainChart-9",
    "mainChart-10",
    "mainChart-11",
    "mainChart-12",
    "mainChart-13",
    "mainChart-14",
    "scatter-0",
    "scatter-1",
    "scatter-2",
    "scatter-3",
    "scatter-4",
]

export const ACTION_TYPE_CHART = {
    addChart: 'add_chart',
    changeType: 'change_type',
    selectYear: 'select_year',
    deleteChart: 'delete_chart',
    zoomChart: 'zoom_chart',
};

export const REPORT_TAB_VALUES = {
    benchmark: 'benchmark',
    monthlyBenchmark: 'monthlyBenchmark',
    yearlyBenchmark: '3YearBenchmark',
    consolidatedBenchmark: 'allBenchmarks',
};
