// initial state for user
export const INITIAL_STATE = {
    user: {
        name: ""
    },
    practice_types: {
        name: ""
    }
};

export const USER_SESSION_DATA = {
    email: '',
    password: ''
}