import Spinner from "components/Spinner";

function FullPageSpinner() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: "0",
        background: "#ffffff",
        zIndex: "100"
      }}
    >
      <Spinner variant='spinner-div' />
    </div>
  );
}

export default FullPageSpinner;
