import { useContext, useReducer, createContext } from "react";

// takes initial state, reducer related to store
// it creates provider, store, and dispatcher for the same context
function createStore(initialState, reducer) {

    // creating a context for store
    const storeContext = createContext();
    //creating a context for dispatcher
    const dispatchContext = createContext();

    // Creating a provider component
    // this will pass down store value and dispatcher to it's children
    function StoreProvider({ children }) {
        const [store, dispatch] = useReducer(reducer, initialState)

        return (
            <dispatchContext.Provider value={dispatch}>
                <storeContext.Provider value={store}>
                    {children}
                </storeContext.Provider>
            </dispatchContext.Provider>
        );
    }


    // it will provider whatever is coming in the store context i.e, store
    function useStore() {
        return useContext(storeContext);
    }

    // it will provider whatever is coming in the dispatch context i.e, dispatch
    function useDispatch() {
        return useContext(dispatchContext);
    }

    // store provider, store consumer, dispatch consumer
    return [
        StoreProvider,
        useStore,
        useDispatch
    ];
}

export default createStore;
