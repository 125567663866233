import THEME from "./common-styled/theme";
import GlobalStyle from "./common-styled/GlobalStyle";
import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import { Suspense } from "react";
import FullPageSpinner from "./components/FullPageSpinner";
import { defaultRoute, PROTECTED_ROUTES, PUBLIC_ROUTES } from "./routes";
import { StoreProvider } from "store/contexts/UserStoreContext";
import useAuthentication from "hooks/useAuthentication";
import { AdminURLs, ClientURLs } from "routes/urls";
import { Buffer } from 'buffer';
import secureLocalStorage from "react-secure-storage";

const currentUser = JSON.parse(secureLocalStorage.getItem('currentUser'))
const selectedPractice = JSON.parse(secureLocalStorage.getItem('selectedPractice'))
const isPracticeSelected = (selectedPractice && Object.keys(selectedPractice)?.length);
const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         refetchOnWindowFocus: false,
      },
   },
});

const AuthenticatedRoute = (props) => (useAuthentication() ?
   (window.location.pathname === "/quickbook/callback" ?
      <Route {...props} /> :
      (["admin", "staff"].includes(currentUser?.role) ?
         ((currentUser?.role === "staff" && window.location.pathname === "/admin/admin-users") ?
            <Redirect to={DefaultPath} /> :
            ((AdminURLs.includes(window.location.pathname)) ?
               ((isPracticeSelected && AdminURLs.includes(window.location.pathname)) ?
                  <Redirect to={DefaultPath} /> :
                  <Route {...props} />) :
               ((isPracticeSelected && ClientURLs.includes(window.location.pathname)) ? <Route {...props} /> : <Redirect to={DefaultPath} />))) :
         (["client", "client_staff", "independent", "co_independent"].includes(currentUser?.role) && ClientURLs.includes(window.location.pathname)) ?
            ((window.location.pathname === "/select-practice" && isPracticeSelected) ?
               <Redirect to={DefaultPath} /> :
               <Route {...props} />) : <Redirect to={DefaultPath} />)) : <Redirect to='/login' />);

// write logic for public routes 
const DefaultPath = defaultRoute() || window.location;
const PublicRoute = (props) => (!useAuthentication() ? <Route {...props} /> : <Redirect to={DefaultPath} />);
function App() {
   global.Buffer = Buffer;
   return (
      <BrowserRouter>
         <QueryClientProvider client={queryClient}>
            <StoreProvider>
               <ThemeProvider theme={THEME}>
                  <GlobalStyle demo={false} />
                  <Suspense fallback={<FullPageSpinner />}>
                     <Switch>
                        <Route
                           exact
                           path="/"
                           render={() => { return (<Redirect to={DefaultPath} />) }}
                        />
                        {/* public routes */}
                        {PUBLIC_ROUTES.map((routeProps) => (
                           <PublicRoute key={routeProps.key} {...routeProps} />
                        ))}
                        {/* authenticated routes */}
                        {PROTECTED_ROUTES.map((routeProps) => (
                           <AuthenticatedRoute
                              key={routeProps.key}
                              {...routeProps}
                           />
                        ))}
                     </Switch>
                  </Suspense>
               </ThemeProvider>
            </StoreProvider>
         </QueryClientProvider>
      </BrowserRouter>
   );
}

export default App;
