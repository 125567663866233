export const NESTED_ADMIN_COMPONENTS = {
  Dashboard: 'dashboard',
  PageNotFound: "page-not-found",
  Practices: 'practices',
  Users: 'admin-users',
  Clients: 'Clients',
  PracticeType: 'practice-types',
  Mapping: 'mapping',
  MappingRule: 'mapping-rules',
  IndustryAverage: 'industry-average',
  BenchmarkReport: 'benchmark-report',
  PresetTrends: 'preset-trends',
  QuickbookConnection: 'quickbook-connection',
  AdminProfile: 'admin-profile'
};

export const NESTED_CLIENT_COMPONENTS = {
  Dashboard: 'snapshot',
  SelectPractice: 'select-practice',
  Trends: 'trends',
  Reports: 'reports',
  ClientProfile: 'client-profile',
  CoIndependentUsers: 'co-independent-users',
  PageNotFound: "page-not-found"
};

export const NESTED_USER_COMPONENTS = {
  PageNotFound: "page-not-found",
};
